var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import FormProvider, { RHFTextField } from '../../../components/hook-form';
import { dispatch } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { paymentNoteValidation } from '../../../Validation/PaymentNote';
import { createPaymentNote, updatePaymentNote } from '../../../redux/slices/paymentNotes/actions';
var PaymentNotesForm = function (_a) {
    var currentPaymentNote = _a.currentPaymentNote, _b = _a.isEdit, isEdit = _b === void 0 ? true : _b;
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var navigate = useNavigate();
    var goListPagePaymentNotes = function () { return navigate(PATH_DASHBOARD.configuration.paymentNotes.root); };
    var defaultValues = useMemo(function () { return ({
        name: (currentPaymentNote === null || currentPaymentNote === void 0 ? void 0 : currentPaymentNote.name) || '',
        description: (currentPaymentNote === null || currentPaymentNote === void 0 ? void 0 : currentPaymentNote.description) || '',
    }); }, [currentPaymentNote]);
    var methods = useForm({
        resolver: yupResolver(paymentNoteValidation),
        defaultValues: defaultValues,
    });
    var handleSubmit = methods.handleSubmit, _c = methods.formState, isSubmitting = _c.isSubmitting, isDirty = _c.isDirty;
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    if (!currentPaymentNote) return [3 /*break*/, 2];
                    return [4 /*yield*/, dispatch(updatePaymentNote({
                            id: currentPaymentNote._id,
                            body: data,
                        }))
                            .unwrap()
                            .then(function (res) {
                            enqueueSnackbar(res.message);
                            goListPagePaymentNotes();
                        })
                            .catch(function (err) { return enqueueSnackbar(err.message, { variant: 'error' }); })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, dispatch(createPaymentNote(data))
                        .unwrap()
                        .then(function (res) {
                        enqueueSnackbar(res.message);
                        goListPagePaymentNotes();
                    })
                        .catch(function (err) { return enqueueSnackbar(err.message, { variant: 'error' }); })];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    error_1 = _a.sent();
                    console.error(error_1);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(FormProvider, __assign({ methods: methods, onSubmit: handleSubmit(onSubmit) }, { children: _jsx(Grid, __assign({ container: true, spacing: 3 }, { children: _jsx(Grid, __assign({ item: true, xs: 12, md: 8 }, { children: _jsxs(Card, __assign({ sx: { p: 3 } }, { children: [_jsxs(Box, __assign({ rowGap: 3, columnGap: 2, display: "grid", gridTemplateColumns: {
                                xs: 'repeat(1, 1fr)',
                                sm: 'repeat(3, 1fr)',
                            } }, { children: [_jsx(RHFTextField, { name: "name", label: "name", type: "input", disabled: !isEdit }), _jsx(RHFTextField, { name: "description", label: "description", type: "input", disabled: !isEdit })] })), _jsx(Stack, __assign({ alignItems: "flex-end", sx: { mt: 3 } }, { children: _jsx(LoadingButton, __assign({ type: "submit", variant: "contained", disabled: isEdit && !isDirty, loading: isSubmitting }, { children: currentPaymentNote ? 'Save Changes' : 'Create payment note' })) }))] })) })) })) })));
};
export default PaymentNotesForm;
